<template>
  <div class="readme-article">
    <h1 id="调配">三、调配</h1>
    <!-- <h2 id="名片分配规则">1.名片分配规则</h2>
    <p>暂无名片分配规则，即将删除</p>
    <p><img src="@/assets/img/call/3-1.png" /></p> -->
    <h2 id="线索回收规则">1.线索回收规则</h2>
    <p>线索回收规则设置，满足规则的线索会自动回收到线索公海中。</p>
    <p><img src="@/assets/img/call/3-1.png" /></p>
    <h2 id="公海领取规则">2.公海领取规则</h2>
    <p>公海领取规则设置，可设置每天领取条数，冷却时间。</p>
    <p><img src="@/assets/img/call/3-2.png" /></p>
    <h2 id="线索锁定规则">3.线索锁定规则</h2>
    <p>线索锁定规则设置，满足规则的线索会自动锁定。</p>
    <p><img src="@/assets/img/call/3-3.png" /></p>
    <h2 id="线索二访规则">4.线索二访规则</h2>
    <p>线索二访规则设置，二访线索领取规则设置。</p>
    <p><img src="@/assets/img/call/3-4.png" /></p>
    <h2 id="组内流转规则">5.组内流转规则</h2>
    <p>组内流转规则设置，组内流转线索领取规则设置。</p>
    <p><img src="@/assets/img/call/3-5.png" /></p>
    <h2 id="流转领取规则">6.流转领取规则</h2>
    <p>流转领取规则设置，流转线索领取条件设置。</p>
    <p><img src="@/assets/img/call/3-6.png" /></p>
  </div>
</template>

<script>
export default {
  name: "call3-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>